<template>
    <section class="content-wrapper">

        <vue-snotify></vue-snotify>
        <div class="form-group" v-for="(item,i) in items" :key="i">
            <input type="number" v-model="item.quantity">
            <p>price= {{item.price}}</p>
        </div>

        <div class="row">
            <div class="col-6">
                <h3>Item à envoyer dans localstorage</h3>
                <p v-for="(item,i) in items" :key="i">
                    prix: {{item.price}}, quantité: {{item.quantity}}
                </p>
                <button type="button" @click="setLocal()"> envoyer</button>
            </div>
            <div class="col-6">
                <h3>item reçu</h3>
                <!-- <p v-for="(local, y) in getLocalResult" :key="y">
                {{local}}
                </p> -->
                <h5>Itemtsotra</h5>
                <p>{{this.items}}</p>
                <h5>localstorage</h5>
                <p>{{this.getLocalResult}}</p>
                <p v-for="(local,y) in getLocalResult" :key="y">
                    prix: {{local.price}}, quantité: {{local.quantity}}
                </p>
                <input type="text" v-model="name">
            </div>
        </div>
        <div class="col-12">
            <input type="text" v-model="newCat" placeholder="ajouter le chat">
            <!-- <button @click="addCat()">ajouter le chat</button> -->
            <!-- <p v-for="n in cats" :key="n">{{n}}</p> -->
        </div>
        <div class="col-12">
            <h3>{{numberOfCats}}</h3>
        </div>
        <div class="col-12">
            <button @click="addNumber()">Add number</button>
        </div>

        <!-- <div class="baliseParent">
            <img src="@/assets/images/import/ct-1.jpg" />
        </div> -->

        <div class="col-12">
            <p>dynamic localstorage</p>
            <input type="text" v-model="localStorage">
            <button @click="pushLocalstorage()">push localStorage</button>
            <h4>{{localStorageResult}}</h4>
        </div>
        <div class="col-12 mb-4">
            <button @click="getProduct()">getProduct</button>
        </div>

        <div class="col-12">
            <div class="overflow-auto">
                <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                ></b-pagination>

                <input type="text" placeholder="recherche" v-model="search">

                <p class="mt-3">Current Page: {{ currentPage }}</p>

                <b-table
                id="my-table"
                :items="searchResult"
                :per-page="perPage"
                :current-page="currentPage"
                small
                ></b-table>
            </div>
        </div>

        <div class="col-12 mt-4">
            <p v-if="$store.state.darkMode==='day'">Mode Jour</p>
            <p v-else-if="$store.state.darkMode==='night'">Mode Nuit</p>
            <p v-else>bug</p>
        </div>

        <div class="col-12 mt-4">
          <b-form @submit.stop.prevent="validate">
            <!-- <b-form-group id="example-input-group-1" label="First Name" label-for="example-input-1">
              <b-form-input
                id="example-input-1"
                name="example-input-1"
                v-model="$v.form.name.$model"
                :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-1-live-feedback">
                This is a required field and must be at least 3 characters.
              </b-form-invalid-feedback>
            </b-form-group> -->

            <b-form-group id="ValidationTest" label="ValidationTest" label-for="ValidationTest">
              <b-form-input
                id="ValidationTest"
                name="ValidationTest"
                v-model="$v.ValidationTest.$model"
                :state="$v.ValidationTest.$dirty ? !$v.ValidationTest.$error : null"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-1-live-feedback">
                This is a required field and must be at least 3 characters.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-button type="submit">submit</b-button>
          </b-form>
        </div>

        <div class="col-12">
          <p v-for="(data, t) in sortDataArray" :key="t">{{data.firstname}}</p>
        </div>

        <div class="col-12">
          <banner/>
        </div>
        <div class="col-12">
          <card-pagination title="test Catégorie">
            <card-image title="Catégorie" description="Desription de la catégorie" column="col-md-4"/>
          </card-pagination>
        </div>
    </section>
</template>

<script type="text/javascript" src="magicscroll/magicscroll.js"></script>
<script src="magicscroll.js"></script>
<script>
import Vue from 'vue'
import Snotify, { SnotifyPosition} from 'vue-snotify'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Banner from '../components/banner.vue'
import CardImage from '../components/element/card-image.vue'
import CardPagination from '../components/pagination/card-pagination.vue'
const options = {
    toast: {
      position: SnotifyPosition.rightTop,
    }
  }
  Vue.use(Snotify, options)
const axios=require('axios').default
export default {
  mixins: [validationMixin],
    name:"test",
    components:{
        Banner,
        CardImage,
        CardPagination
    },
    data () {
        return {
          title: 'Card',
          stringLength: "akory leka dada",
            items: [
                { price: 2, quantity: 1},
                { price: 3, quantity: 2},
                { price: 1, quantity: 1}
            ],
            getLocalResult:'',
            name:'',
            newCat: null,
            cats:[],
            number:0,
            localStorage:'',
            localStorageResult:'',
            currentPage:1,
            perPage:3,
            dataArray: [
                { firstname: 'test1', lastname: 'Doe', email: 'johndoe@example.com', rang:1, date: "2021-07-24 10:07:00"  },
                { firstname: 'test2', lastname: 'Doe', email: 'janedoe@example.com', rang:4, date: "2021-06-24 10:07:00"  },
                { firstname: 'test3', lastname: 'Doo', email: 'johndoo@example.com', rang:3, date: "2021-06-24 11:07:00"  }
            ],
            test: [
                { champ1: 'champ test', champ2: 'champ test 2'},
                { champ1: 'champ test', champ2: 'champ test 2'},
                { champ1: 'champ test', champ2: 'champ test 2'}
            ],
            search:'',
            ValidationTest:'',
            form: {
              name:null
            }
        }
    },
    validations: {
      ValidationTest: {required}
    },
    computed: {
      sortDataArray() {
        let sort= this.dataArray.slice().sort( (a, b) => new Date(b.date) - new Date(a.date) )
        return sort
      },
      xData() {
        let testArray=[]
        // testArray=this.dataArray.slice(this.dataArray.length - 2,this.dataArray.length)
        testArray=this.dataArray.slice(Math.max(this.dataArray.length - 5, 0))
        console.log({testArray})
        return testArray
      },
        numberOfCats() {
            return this.cats.length
        },
        rows() {
            return this.dataArray.length
        },
        searchResult () {
            return this.search ? this.dataArray.filter( item=>
                item.firstname.includes(this.search)||
                item.lastname.includes(this.search)||
                item.email.includes(this.search)
            )
            :this.dataArray
        }
    },
    methods: {
        pushLocalstorage() {    
            localStorage.setItem('dynamicLocalstorage', this.localStorage)
        },
        setLocal() {
            let itemStringify= JSON.stringify(this.items)
            localStorage.setItem('items',itemStringify)
            // localStorage.setItem('items',this.items)
        },
        addCat(){
            this.cats.push(this.newCat);
            this.newCat='';
            const parsed = JSON.stringify(this.cats);
            localStorage.setItem('cats', parsed);
        },
        getMyinfos() {
          axios.get(`myinfos`,{withCredentials:true})
          .then(resMyinfos => console.log({resMyinfos}))
          .catch(errinfos => console.log({errinfos}))
      },
      getProduct(){
          axios.get('products/categories/01F5FY7NS5NATDR0M6R1GBMTNA')
          .then(resProduct=> {console.log(resProduct)})
          .catch(()=>console.log('erreur'))
      },
      allTable() {
          let allTable=[]
          allTable.push(this.dataArray['firstname'])
          allTable.push(this.test['champ1'])
          console.log({allTable})
      },
      onSubmit() {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        alert('requête envoyée')
        // Form submit logic
      },

      validate() {
        this.$v.$touch()
        if (this.$v.$anyError) {
          return
        }
        console.log('submit');
      }
    },
    mounted() {
        this.getMyinfos()
        this.localStorageResult=localStorage.getItem('dynamicLocalstorage')

        this.getLocalResult= JSON.parse(localStorage.getItem('items'))
        if(localStorage.name) {
            this.name = localStorage.name
        }

        this.allTable()

        this.$snotify.success('Example body content', {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        });

        console.log('data array', this.dataArray[0]['email']);
        console.log('string length', this.stringLength.length);
    },
    watch: {
    }
}
</script>
<!--<script>
const products =  [
{
  "ulid": "01F10DCA33F377C4XZAGBEF7CH",
  "ulidcategory": [
    "01F5FY7NS5NATDR0M6R1GBMTNA"
  ],
  "ulidcollection": [
    "01EYX4T2M5XRZVBXAS89V57RM6"
  ],
  "name": "Miel",
  "price": 8,
  "datepublished": "2021-03-01 16:53:01",
  "datemodified": "2021-07-02 08:08:18"
},
  {
    "ulid": "01F2TVVAAFHP85JV8VRQ3Z6G6G",
    "ulidcategory": [
      "01F5FY7NS5NATDR0M6R1GBMTNA"
    ],
    "ulidcollection": [
      "01EYX4T2M5XRZVBXAS89V57RM6"
    ],
    "name": "Miel",
    "price": 8,
    "datepublished": "2021-03-01 16:53:01",
    "datemodified": "2021-07-02 08:07:14"
  },
  {
    "ulid": "01F2TVVAAFHP85JV8VRQ3Z6G6G",
    "ulidcategory": [
      "01F5FY7NS5NATDR0M6R1GBMTNATSIORY"
    ],
    "ulidcollection": [
      "01EYX4T2M5XRZVBXAS89V57RM6"
    ],
    "name": "Tsiory",
    "price": 8,
    "datepublished": "2021-03-01 16:53:01",
    "datemodified": "2021-07-02 08:07:14"
  }
]
const categories = [
{
            "ulid": "01F5FY7NS5NATDR0M6R1GBMTNA",
            "category": "Miel",
            "description": "Miel",
            "datepublished": "2021-03-18 16:53:01"
        },
        {
            "ulid": "01F8CWZZ3C0WHZ078FWMV3SPWP",
            "category": "Miel et Propolis",
            "description": "Miel et Propolis",
            "datepublished": "2021-06-17 11:51:06"
        },
        {
            "ulid": "01F8CX0AC5YEWQGQF1MPTVSQ4D",
            "category": "Confiture",
            "description": "Confiture",
            "datepublished": "2021-06-17 11:51:38"
        },
        {
            "ulid": "01F8CX0X11T1XHAKGFQM58NXCM",
            "category": "Gelée de plante",
            "description": "Gelée de plante",
            "datepublished": "2021-06-17 11:51:56"
        }
];

let filteredP = [];

for (let i = 0; i < products.length; i++) {
	for (let j = 0; j < categories.length; j++) {
  	if (products[i]["ulidcategory"].includes(categories[j]["ulid"])) {
    	filteredP.push(products[i]);
    }
  }
}

console.log({filteredP});
</script> -->

<!--
<style scoped>

.baliseParent{
  position:relative;
  display:inline-block;
}
.baliseParent:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
 background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,212,255,0.18809530648196782) 0%, rgba(255,0,0,1) 52%, rgba(4,4,9,1) 99%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

img{
  display:block
}

</style> -->